import { SeatDto, TableModalTypes, TableState } from './tableState';
import { TableIncomingEvents, TableInternalEvents, PlayerActions } from './tableActions';
import { TableActionPerformedMsg, TableIncomingEventMsg } from './tableMessages';
import { ChipsPushing, Operations } from './tableOperations';
import { getTableEventSound } from './tableUtils';
import { parseWinningHand } from '@/features/poker-table/utils/parseWinningHand';

export type TableStates = Record<string, TableState>;

const initialState: TableStates = {};

const MESSAGE_SHOW_TIME = 3000;

function tableReducer(state = initialState, action: TableIncomingEventMsg): TableStates {
  switch (action.type) {
    case PlayerActions.SET_WAIT_FOR_BIG_BLIND: {
      const currTable = state[action.tableId];

      if (!currTable) {
        return state;
      }

      return {
        ...state,
        [currTable.tableId]: {
          ...currTable,
          playerSeat: {
            ...currTable.playerSeat,
            waiting: action.data.waiting,
          },
        },
      } as any;
    }

    case TableInternalEvents.SHOW_TABLE_MODAL: {
      const currTable = state[action.data.tableId];

      if (!currTable) {
        return state;
      }

      return {
        ...state,
        [currTable.tableId]: {
          ...currTable,
          modal: {
            type: action.data.modalType,
          },
        },
      };
    }

    case TableInternalEvents.CLOSE_TABLE_MODAL: {
      const currTable = state[action.data.tableId];

      if (!currTable) {
        return state;
      }

      return {
        ...state,
        [currTable.tableId]: {
          ...currTable,
          modal: undefined,
        },
      };
    }

    case TableIncomingEvents.DUPLICATE_TABLE_CONNECTION: {
      const currTable = state[action.data.tableId];

      if (!currTable) {
        return state;
      }

      return {
        ...state,
        [currTable.tableId]: {
          ...currTable,
          modal: {
            type: TableModalTypes.DUPLICATE_TABLE_CONNECTION,
          },
        },
      };
    }
    case TableInternalEvents.TABLE_JOINING: {
      const { tableId, joining } = action.data;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      return {
        ...state,
        [tableId]: {
          ...table,
          joining,
        },
      };
    }
    case TableIncomingEvents.TABLE_JOINED: {
      const { tableId, seat } = action.data;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      const seats = table.seats.map((existingSeat, index) => (index === seat.seat ? seat : existingSeat));

      const newState = {
        ...state,
        [tableId]: {
          ...table,
          sound: undefined,
          seats,
        },
      };

      if ('playerSeat' in action.data) {
        newState[tableId].playerSeat = action.data.playerSeat;
        newState[tableId].joining = false;
      }

      return newState;
    }

    case TableIncomingEvents.SEAT_RESERVED: {
      const { tableId, seat } = action.data;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      const newState = {
        ...state,
        [tableId]: {
          ...table,
          sound: undefined,
          seats: table.seats.map((existingSeat, index) => (index === seat.seat ? seat : existingSeat)),
        },
      };

      if ('playerSeat' in action.data) {
        newState[tableId].playerSeat = action.data.playerSeat;
      }

      return newState;
    }

    case TableIncomingEvents.RESERVE_SEAT_CANCELLED: {
      const { tableId, seat } = action.data;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      return {
        ...state,
        [tableId]: {
          ...table,
          sound: undefined,
          seats: table.seats.map((existingSeat, index) =>
            index === seat.seat ? { ...existingSeat, ...seat } : existingSeat,
          ),
        },
      };
    }

    case TableIncomingEvents.SIT_OUT: {
      const { tableId, userId } = action.data;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      const seats = table.seats.map((seat) => {
        if (seat?.userId === userId) return { ...seat, active: false };
        return seat;
      });

      const playerSeat =
        table.playerSeat?.userId === userId ? { ...table.playerSeat, active: false } : table.playerSeat;

      return {
        ...state,
        [tableId]: {
          ...table,
          sound: undefined,
          seats,
          playerSeat,
        },
      };
    }

    case TableIncomingEvents.SIT_IN: {
      const { tableId, userId } = action.data;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      const seats = table.seats.map((seat) => {
        if (seat?.userId === userId) return { ...seat, active: true };
        return seat;
      });

      const playerSeat = table.playerSeat?.userId === userId ? { ...table.playerSeat, active: true } : table.playerSeat;

      return {
        ...state,
        [tableId]: {
          ...table,
          sound: undefined,
          seats,
          playerSeat,
        },
      };
    }

    case PlayerActions.LEAVE_TABLE: {
      const { tableId } = action;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      const { [tableId]: _, ...restState } = state;
      return restState;
    }

    case TableIncomingEvents.TABLE_LEFT: {
      const { tableId, userId } = action.data;
      const table = state[tableId];

      if (!table) {
        return state;
      }

      const seats = table.seats.map((seat) => {
        if (seat?.userId === userId) return null;
        return seat;
      });

      return {
        ...state,
        [tableId]: {
          ...table,
          sound: undefined,
          seats,
          playerSeat: table.playerSeat?.userId === userId ? undefined : table.playerSeat,
        },
      };
    }

    case TableIncomingEvents.TABLE_STATE: {
      const { data } = action;

      const tableId = action.tableId || data.tableId;

      const op = data.lastOperation?.operation;

      if (op?.player_index !== undefined && state[tableId]?.playerSeat?.playerIndex === op?.player_index) {
        return state;
      }

      return {
        ...state,
        [tableId]: {
          ...state[tableId],
          ...data,
        },
      };
    }

    case TableIncomingEvents.TABLE_CONNECTED: {
      const { tableId } = action;
      const table = state[tableId];

      if (!table) return state;

      return {
        ...state,
        [tableId]: { ...table, isConnected: true },
      };
    }

    case TableIncomingEvents.TABLE_DISCONNECTED: {
      const { tableId } = action;
      const table = state[tableId];

      if (!table) return state;

      return {
        ...state,
        [tableId]: { ...table, isConnected: false },
      };
    }

    case TableIncomingEvents.TABLES_DISCONNECTED: {
      const newState: TableStates = {};
      Object.keys(state).forEach((tableId) => {
        newState[tableId] = {
          ...state[tableId],
          isConnected: false,
        };
      });
      return newState;
    }

    case TableIncomingEvents.ERROR: {
      const newState: TableStates = {};
      Object.keys(state).forEach((tableId) => {
        newState[tableId] = {
          ...state[tableId],
          error: action.error,
        };
      });
      return newState;
    }

    case TableIncomingEvents.HAND_FINISHED: {
      const tableId = action.data.tableId;
      const table = state[tableId];

      if (!table) return state;

      const seats = table.seats.map((seat) => {
        if (seat) {
          return {
            ...seat,
            holeCards: [],
            isActingPlayer: false,
            betAmount: undefined,
            timer: undefined,
            timeRemaining: undefined,
          };
        }
        return seat;
      });

      const playerSeat = table.playerSeat
        ? {
            ...table.playerSeat,
            isActingPlayer: false,
            betAmount: undefined,
            timer: undefined,
            timeRemaining: undefined,
            holeCards: [],
            foldedCards: [],
          }
        : table.playerSeat;

      return {
        ...state,
        [tableId]: {
          ...table,
          seats,
          playerSeat,
          handState: undefined,
          lastOperationLabel: undefined,
          winnerSeat: undefined,
          winnerBetAmount: undefined,
          winnersData: undefined,
        },
      };
    }

    case TableInternalEvents.SET_TOGGLE_BB_DISPLAY: {
      const { tableId, displayInBBEnabled } = action.payload;
      const table = state[tableId];
      if (!table) {
        return state;
      }

      return {
        ...state,
        [tableId]: {
          ...table,
          displayInBBEnabled: displayInBBEnabled,
        },
      };
    }

    case TableIncomingEvents.PLAYER_TURN: {
      const { tableId, seat, timer, timeRemaining, usingTimebank } = action.data;
      const table = state[tableId];

      if (!table || seat === undefined) return state;

      let { playerSeat } = table;

      if (playerSeat?.seat === seat) {
        playerSeat = {
          ...playerSeat,
          timebank: usingTimebank ? timeRemaining : playerSeat.timebank,
        };
      }
      const sound = getTableEventSound(action);

      return {
        ...state,
        [tableId]: {
          ...table,
          playerSeat,
          sound,
          seats: updateSeatsTimer(table.seats, seat, timer, timeRemaining, usingTimebank),
        },
      };
    }
    case TableInternalEvents.SOUND: {
      const { tableId, lastOperation } = action.data;
      const table = state[tableId];

      if (!table) return state;

      const tableActionPerformedMsg: TableActionPerformedMsg = {
        type: TableIncomingEvents.ACTION_PERFORMED,
        tableId,
        data: {
          lastOperation,
        },
      };

      const sound = getTableEventSound(tableActionPerformedMsg);

      return {
        ...state,
        [tableId]: {
          ...table,
          sound,
        },
      };
    }
    case TableIncomingEvents.ACTION_PERFORMED: {
      const tableId = action.data.tableId || action.tableId;
      const table = state[tableId];
      const actionPerformed = action.data.lastOperation;
      let lastOperationLabel;

      const sound = table.playerSeat?.playerIndex !== actionPerformed.player_index && getTableEventSound(action);

      if (!tableId) {
        return state;
      }

      if (action.data?.lastOperation?.action) {
        lastOperationLabel = {
          label: action.data.lastOperation.action,
          playerIndex: action.data.lastOperation.player_index,
          time: MESSAGE_SHOW_TIME,
          seat: action.data.lastOperation.seat,
        };
      }

      if (action.data?.lastOperation?.subtype === Operations.ChipsPushing) {
        const potInfo = action.data?.lastOperation as ChipsPushing;

        const winningCards = potInfo?.info
          ?.flatMap((info) => info.players)
          ?.flatMap((p) => p.winning_hand)
          ?.map(parseWinningHand);

        return {
          ...state,
          [tableId]: {
            ...table,
            actionPerformed,
            lastOperationLabel,
            sound,
            winnersData: {
              pot: potInfo.amounts,
              playerIndexes: potInfo.info.flatMap((info) => info.players).flatMap((p) => p.player_index),
              handMessages: winningCards.map((hand) => hand.text),
              cards: winningCards.flatMap((p) => p.cards),
            },
          },
        };
      }

      if (action.data?.lastOperation?.subtype === Operations.ChipsPulling) {
        return {
          ...state,
          [tableId]: {
            ...table,
            actionPerformed,
            winnerSeat: action.data.lastOperation.seat,
            winnerBetAmount: action.data.lastOperation.amount,
            lastOperationLabel,
            sound,
          },
        };
      }

      return {
        ...state,
        [tableId]: {
          ...table,
          actionPerformed,
          lastOperationLabel,
          winnersData: undefined,
          sound,
        },
      };
    }

    default:
      return state;
  }
}

export default tableReducer;

function updateSeatsTimer(
  seats: (SeatDto | null)[],
  seat: number,
  timer: number,
  timeRemaining: number,
  usingTimeBank: boolean,
): (SeatDto | null)[] {
  return seats.map((seatDto) => {
    if (!seatDto) return null;

    if (seatDto.seat === seat) {
      return {
        ...seatDto,
        timer,
        usingTimeBank,
        timeRemaining: timeRemaining > 0 ? timeRemaining : undefined,
      };
    } else {
      return {
        ...seatDto,
        usingTimeBank: false,
        timer: undefined,
        timeRemaining: undefined,
      };
    }
  });
}
