import { useState, useEffect, useCallback } from 'react';

const useIsPortraitView = () => {
  const [isPortraitView, setIsPortraitView] = useState<boolean>(
    () => window.matchMedia('(orientation: portrait)').matches,
  );

  const updateOrientation = useCallback(() => {
    setIsPortraitView(window.matchMedia('(orientation: portrait)').matches);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    mediaQuery.addListener(updateOrientation);
    return () => mediaQuery.removeListener(updateOrientation);
  }, [updateOrientation]);

  return isPortraitView;
};

export default useIsPortraitView;
