import { Card, PokerVariant, EventSounds } from './tableTypes';
import { LastOperationMsg, LastOperationLabel, Operation } from './tableOperations';

export enum TableModalTypes {
  DUPLICATE_TABLE_CONNECTION = 'DUPLICATE_TABLE_CONNECTION',
  TABLE_INFO = 'TABLE_INFO',
  LEAVE_TABLE = 'LEAVE_TABLE',
}

export interface TableModalState {
  type: TableModalTypes;
}

export interface SeatDto {
  seat: number;
  userId: string;
  alias: string;
  stack: number;
  totalStack?: number;
  active: boolean;
  playerIndex: number | null;
  betAmount?: number;
  holeCards: Card[];
  isActingPlayer: boolean;
  isDealer: boolean;
  timer?: number;
  usingTimeBank?: boolean;
  timeRemaining?: number;

  cardIndexes: number[];
  cardsDiscarded?: number;

  status: boolean;

  skippedSB: boolean;
  skippedBB: boolean;

  newPlayer: boolean;
  disconnected: boolean;
  leftTable: boolean;
}

interface PreAction {
  label: string;
  amount?: number;
}

export interface PreActionsAvailable {
  foldCheck?: PreAction;
  checkCall?: PreAction;
  betRaise?: PreAction;
  standPatOrDiscard?: PreAction;
}

export interface PlayerSeatDto extends SeatDto {
  autoTopup: boolean;
  autoTopUpBuyIn?: number;
  autoTopUpThreshold?: number;
  waiting: boolean;
  timebank: number;
  disconnectionTimebank: number;
  availableActions?: AvailableActions;
  foldedCards: Card[];
  preActionsAvailable?: PreActionsAvailable;
  handsDealt: number;
}

export interface HandState {
  status: boolean;
  starting_stacks: number[];
  stacks: number[];
  bets: number[];
  pots: Pot[];
  board_cards: Card[][];
  blinds_or_straddles: number[];
  street_index: number | undefined;
  actor_index: number | undefined;
  stander_pat_or_discarder_index: number | undefined;
  hole_dealee_index: number | undefined;
  showdown_index: number | undefined;
  player_count: number;
  all_in_show_status: boolean;
  checking_or_calling_amount: number | undefined;
  effective_bring_in_amount: number | undefined;
  min_completion_betting_or_raising_to_amount: number | undefined;
  pot_completion_betting_or_raising_to_amount: number | undefined;
  max_completion_betting_or_raising_to_amount: number | undefined;
  statuses: boolean[];
  hole_cards: Card[][];
  all_hole_dealing_statuses: boolean[];
  totalPot: number;
  streets: Street[];
  winning_hands: Card[][];
}

export interface Street {
  hole_dealing_statuses: boolean[];
  card_burning_status: boolean;
  skip_betting: boolean;
  board_dealing_count: number;
  draw_status: boolean;
  opening: string;
  min_completion_betting_or_raising_amount: number;
  max_completion_betting_or_raising_count: number;
}

export interface TableStateDto {
  tableId: string;
  url: string;
  handId: string;
  name: string;
  type: string;
  ante: number;
  smallBlind: number;
  bigBlind: number;
  maxPlayers: number;
  minBuyin: number;
  maxBuyin: number;
  smallBet: number;
  bigBet: number;
  bringIn: number;
  bettingCap: number;
  dealerSeat: number;
  currentVariant: PokerVariant;
  variants: PokerVariant[];
  gameParams: GameParams[];
  handState?: {
    handId: string;
    state: HandState;
    availableActions: AvailableActions;
    lastOperation?: Operation;
  };

  seats: (SeatDto | null)[];
  waitlist: string[];

  game: string;

  currentDraw?: number;
  totalDraws?: number;
  handsInCurrentGame: number;
  handsPerGame: number;
  contractId: number;
  contractAddress: `0x${string}`;
  isRealMoney: boolean;
}

export interface TableState extends TableStateDto {
  id?: string;
  actionPerformed?: Operation;
  lastOperation?: LastOperationMsg;
  lastOperationLabel?: LastOperationLabel;
  isConnected: boolean;
  winnerSeat?: number;
  winnerBetAmount?: number;

  error?: any;
  seats: (SeatDto | null)[];
  playerSeat?: PlayerSeatDto;
  sound?: EventSounds;
  winnersData?: WinningData;
  displayInBBEnabled?: boolean;
  modal?: TableModalState;
  joining: boolean;
}

export interface GameParams {
  index: number;
  name: string;
  variantId: string;
  ante?: number;
  sbAnte?: number;
  bbAnte?: number;
  bringIn?: number;
  bettingCap?: number;
  smallBlind?: number;
  bigBlind?: number;
  smallBet?: number;
  bigBet?: number;
  rakePercent: number;
  rakeCap: number;
  rakeCapHU: number;
}

export interface TableJoinedDto {
  userId: string;
  tableId: string;
  seat: SeatDto;
  playerSeat?: PlayerSeatDto;
}

export interface Pot {
  amount: number;
  raked_amount: number;
  unraked_amount: number;
  playerIndices: number[];
}

export enum HandActions {
  canCollectBets = 'canCollectBets',
  canPostAnte = 'canPostAnte',
  canPostBlindOrStraddle = 'canPostBlindOrStraddle',
  canBurnCard = 'canBurnCard',
  canDealHole = 'canDealHole',
  canDealBoard = 'canDealBoard',
  canStandPatOrDiscard = 'canStandPatOrDiscard',
  canPostBringIn = 'canPostBringIn',
  canShowOrMuckHoleCards = 'canShowOrMuckHoleCards',
  canKillHand = 'canKillHand',
  canPushChips = 'canPushChips',
  canPullChips = 'canPullChips',
  canWinNow = 'canWinNow',
  canFold = 'canFold',
  canCheck = 'canCheck',
  canCall = 'canCall',
  canBet = 'canBet',
  canRaise = 'canRaise',
  canCompleteBetOrRaise = 'canCompleteBetOrRaise',
  canComplete = 'canComplete',
}

export type AvailableActions = {
  [K in HandActions]: boolean | undefined;
};

export interface WinningData {
  pot: number[];
  playerIndexes: number[];
  handMessages: string[];
  cards: Card[];
}
